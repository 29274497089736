
import axios from "@/plugins/axios.js"

const actions = {
    fetchOrders({ commit, state }, force = false) {
      if(!force && state.orders.length) return
        return new Promise((resolve, reject) => {
          axios.get("/orders")
            .then((response) => {
              if(response.data.success){
                commit('SET_ORDERS', response.data.data)
              }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
      },
      fetchOrder({ commit }, orderId) {
        return new Promise((resolve, reject) => {
          axios.get(`/orders/${orderId}/show`)
            .then((response) => {
              if(response.data.success){
                commit('SET_ORDER', response.data.data)
              }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
      },
      fetchDeliveries({ commit }, orderId) {
        return new Promise((resolve, reject) => {
          axios.get(`/deliveries/${orderId}`)
            .then((response) => {
              if(response.data.success){
                commit('SET_DELIVERIES', response.data.data)
              }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
      },
      fetchDelivery({ commit }, orderId) {
        return new Promise((resolve, reject) => {
          axios.get(`/deliveries/${orderId}/show`)
            .then((response) => {
              if(response.data.success){
                commit('SET_DELIVERY', response.data.data)
              }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
      },
}

const getters = {}

const mutations = {
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_ORDER(state, order){
      state.order = order
    },
    UPDATE_ORDER(state, order){
      Object.assign(state.order, order)
      if(state.orders.length){
        const dataIndex = state.orders.findIndex((o) => o.id == order.id);
        Object.assign(state.orders[dataIndex], order);
      }
    },

    /**************** THIS IS FOR DELIVERIES********************/
    SET_DELIVERIES(state, deliveries){
      state.order.deliveries = [...deliveries]
    },
    SET_DELIVERY(state, delivery){
      state.delivery = {...delivery}
    },
    UPDATE_DELIVERY(state, delivery){
      Object.assign(state.delivery, delivery)
      if(state.order.deliveries.length){
        const dataIndex = state.order.deliveries.findIndex((a) => a.id == delivery.id);
        Object.assign(state.order.deliveries[dataIndex], delivery);
      }
    },

    /**************** THIS IS FOR MESSAGES********************/
    SET_MESSAGES(state, messages){
      state.delivery.messages = [...messages]
    },
    ADD_MESSAGE(state, message){
      state.delivery.messages.push(message)
    },
    UPDATE_MESSAGE(state, message){
      const dataIndex = state.delivery.messages.findIndex((a) => a.id == message.id);
      Object.assign(state.delivery.messages[dataIndex], message);
    },
    DELETE_MESSAGE(state, messageId){
      const itemIndex = state.delivery.messages.findIndex((item) => item.id == messageId)
      state.delivery.messages.splice(itemIndex, 1)
    },
}

const state = {
    orders: [],
    order: {},
    delivery: null
}



export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
