import axios from "@/plugins/axios.js"
import { useToast } from 'vue-toastification'
const toast = useToast()

const actions = {
  alertSuccess({}, message) {
    toast.success(message)
  },
  alertError({}, message) {
    toast.error(message)
  },
  changeLoaderValue({ commit }, val = false) {
    commit("TOGGLE_LOADER", val)
  },
  fetchActiveUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/account/show")
        .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_INFO', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchSubscription({ commit, rootState }, force = false) {
    if(!force && rootState.subscription) return
    return new Promise((resolve, reject) => {
      axios.get("/subscriptions/show")
        .then((response) => {
          if(response.data.success){
            commit('SET_SUBSCRIPTION', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchTasks({ commit, rootState }) {
    if(rootState.catalog.tasks.length) return
    return new Promise((resolve, reject) => {
      axios.get('/tasks')
        .then((response) => {
          if(response.data.success){
            commit('SET_TASKS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchPlans({ commit, rootState }, params = "") {
    if(rootState.catalog.plans.length) return
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.config.domain}/api/plans${params}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_PLANS', response.data.data.plans)
            commit('SET_ATTRIBUTES', response.data.data.attributes)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  logoutUser({ commit, rootState }, action) {
    commit("LOGOUT_USER")
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.config.domain}/api/logout`)
      .then((response) => {
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCountries({ commit, rootState }) {
    if(rootState.countries.length) { return;}
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.config.domain}/api/countries`)
      .then((response) => {
      if(response.data.success){
        commit('SET_COUNTRIES', response.data.data)
      }
      resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  loadStripe({}) {
    if(document.getElementById("StripeScript")){return }
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = `https://js.stripe.com/v3/`;
      script.async = true;
      script.id = "StripeScript"
      script.onreadystatechange = script.onload = function () {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function () {
            resolve()
          }, 500)
        }
      }
      document.head.appendChild(script);
    })
  },
  getClientCountry({commit}, val = false){
    fetch('https://api.ipregistry.co/?key=tryout')
      .then(function (response) {
          return response.json();
      })
      .then(function (payload) {
        commit('SET_COUNTRY', payload.location.country);
      });
  },
}

export default actions
